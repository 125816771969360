<template>
  <div>
    <section class="pp-sec-pad">
      <div class="cp-c-pad container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="box-wrapper"><h6 class="form-widget nav-title">{{ $t('page.contact.consult.title') }}</h6>
              <h3 class="form-widget">{{ $t('page.contact.consult.form.title') }}</h3>
              <p class="form-widget">{{ $t('page.contact.consult.form.desc') }}</p>
              <div class="elementor-divider">
                <span class="elementor-divider-separator"></span>
              </div>
              <form class="">
                <div role="group" class="form-group">
                  <label class="d-block">{{ $t('page.contact.consult.form.name') }}:</label>
                  <div>
                    <input type="text" :placeholder="$t('page.contact.consult.form.name')" required="required" aria-required="true" class="form-control">
                  </div>
                </div>
                <div role="group" class="form-group">
                  <label class="d-block">{{ $t('page.contact.consult.form.company') }}:</label>
                  <div>
                    <input type="text" :placeholder="$t('page.contact.consult.form.company')" class="form-control"><!---->
                  </div>
                </div>
                <div role="group" class="form-group">
                  <label class="d-block">{{ $t('page.contact.consult.form.email') }}:</label>
                  <div>
                    <input type="text" :placeholder="$t('page.contact.consult.form.email')" required="required" aria-required="true" class="form-control">
                  </div>
                </div>
                <div role="group" class="form-group">
                  <label class="d-block">{{ $t('page.contact.consult.form.phone') }}:</label>
                  <div>
                    <input type="text" :placeholder="$t('page.contact.consult.form.phone')" required="required" aria-required="true" class="form-control">
                  </div>
                </div>
                <div role="group" class="form-group">
                  <label for="textarea" class="d-block" >{{ $t('page.contact.consult.form.message') }}:</label>
                  <div>
                    <textarea :placeholder="$t('page.contact.consult.form.message')" wrap="soft" class="form-control" style="resize: none; overflow-y: scroll; height: 86px;"></textarea>
                  </div>
                </div>
              </form>
              <button type="submit" class="elementor-button elementor-size-md">
                <span>
                  <span class="elementor-button-icon"></span>
                  <span class="elementor-button-text">{{ $t('page.contact.consult.form.submit') }} </span>
                </span>
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="box-wrapper-right">
              <h6 class="form-widget nav-title">{{ $t('page.contact.info.title') }}</h6>
              <h3 class="form-widget">{{ $t('page.contact.info.company.title') }}</h3>
              <p class="form-widget">{{ $t('page.contact.info.company.desc') }}</p>
              <div class="elementor-divider">
                <span class="elementor-divider-separator"></span>
              </div>
              <!--
              <div>
                <h3>{{ $t('page.contact.info.address.country') }}</h3>
                <p>{{ $t('page.contact.info.address.detail') }}</p>
              </div>
              <div>
                <h3>{{ $t('page.contact.info.phone') }}</h3>
                <p>+80111111111111</p>
              </div>
              -->
              <div>
                <h3>{{ $t('page.contact.info.email') }}</h3>
                <p>hkppay@hkdintl.com</p>
              </div>
              <!--
              <div>
                <h3>{{ $t('page.contact.info.telegram') }}</h3>
                <p>@1111111</p>
              </div>
              <div>
                <h3>{{ $t('page.contact.info.whatsapp') }}</h3>
                <p>+1111111</p>
              </div>
              <div>
                <h3>{{ $t('page.contact.info.skype') }}</h3>
                <p>+1111111</p>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContactView"
}
</script>

<style scoped>
.box-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .1);
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 3em;
  border-radius: 25px 25px 25px 25px;
  flex-wrap: wrap;
  align-content: flex-start
}

.form-widget:not(:last-child) {
  margin-bottom: 20px
}

h1, h3, h6 {
  display: block
}

.nav-title {
  color: #54595f;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.6px;
  height: 1px
}

.elementor-divider {
  padding-top: 15px;
  padding-bottom: 15px
}

.elementor-divider .elementor-divider-separator {
  display: block;
  width: 100%;
  border-top: 1px solid #edf1fc
}

.elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #ff9738;
  box-shadow: inset -100px 0 68px -1px #ff8743;
  border-radius: 50px 50px 50px 50px;
  min-height: 47px;
  flex-basis: 100%;
  color: #fff;
  font-size: 16px;
  padding: 15px 30px;
  border: none;
  fill: #fff;
  text-align: center;
  transition: all .3s;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.box-wrapper-right {
  display: flex;
  flex-direction: column;
  padding: 3em;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box
}
</style>